import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { base_url } from 'src/common';
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { DatePicker as RSDatePicker } from 'rsuite';
import CalenderIcon from "../../../../assets/images/calender.svg"

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  btncontainer: {
    '& .Mui-selected': {
      color: '#6941C6',
    },
    '& button': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#344054',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      marginRight: '2rem',
      border: '0px !important',
      borderRadius: '5px !important',
      '&:focus': {
        color: '#6941C6',
      },
      '& svg': {
        width: '20px !important',
        height: '20px !important',
        marginLeft: '12px',
      },
    },
  },
  addButton: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '144px',
    height: '40px',
    background: '#1570EF',
    border: '1px solid #1570EF',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    '&:hover': {
      background: '#1570EF',
    },
  },
  clientField: {
    lineHeight: "1.4375em",
    fontSize: "1rem",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: 400,
    color: "#637381",
    boxSizing: "border-box",
    border: "1px solid #d8dde1 !important",
    cursor: "text",
    backgroundColor: "#f9fafb",
    display: "inline-flex",
    padding: "0px 0px 0px 8px",
    alignItems: "center",
    width: "100%",
    position: "relative",
    borderRadius: "8px",
    paddingRight: "14px"
  }
}));

export default function AddSession(props) {
  const navigate = useNavigate();
  const classes = useStyle();
  const formData = new FormData();
  const [modal, setModal] = useState(false);
  const [loadingState, setloadingState] = useState(false);
  const [time, setTime] = useState([
    { key: 0, label: '15min' },
    { key: 1, label: '30min' },
    { key: 2, label: '45min' },
    { key: 3, label: '60min' },
    { key: 4, label: '75min' },
    { key: 5, label: '90min' },
    { key: 6, label: '105min' },
    { key: 7, label: '120min' },
  ]);
  const [status, setStatus] = useState([
    { key: 0, label: 'booked' },
    { key: 1, label: 'completed' },
    { key: 2, label: 'changed' },
    { key: 3, label: 'cancelled' }
  ]);
  const [other, setOther] = useState('');

  const [allClients, setallClients] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [allParticipants, setallParticipants] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const [allProgrammes, setallProgrammes] = useState([]);
  const [autoValue, setAutoValue] = useState('');
  const [dateMatch, setDateMatch] = useState();
  const [alignment, setAlignment] = useState(0);
  const [detailsArray, setDetailsArray] = useState([0]);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionDate, setSessionDate] = useState(false);
  const [sessionCompleteDate, setSessionCompleteDate] = useState(false);
  const [sessionDateError, setSessionDateError] = useState("");
  const [sessrionCompleteDateError, setSessionCompleteDateError] = useState("");
  const [companyID, setcompanyID] = useState([
    { key: 0, label: 'Articulating your career goals' },
    { key: 1, label: 'Articulating your value add to the firm' },
    { key: 2, label: 'Building resilience' },
    { key: 3, label: 'Building self confidence' },
    { key: 4, label: 'Business development skills' },
    { key: 5, label: 'Career choices' },
    { key: 6, label: 'Creating a strategic network ' },
    { key: 7, label: 'Developing a leadership style' },
    { key: 8, label: 'Developing sponsors' },
    { key: 9, label: 'Difficult relationships' },
    { key: 10, label: 'Managing role transitions' },
    { key: 11, label: 'Personal pitch looking forward' },
    { key: 12, label: 'Preparing for key meetings' },
    { key: 13, label: 'Promotion business cases' },
    { key: 14, label: 'Sustainable work balance' },
    { key: 15, label: 'Time management' },
    { key: 16, label: 'Virtual workplaces' },
    { key: 17, label: 'Your personal career story' },
    { key: 18, label: 'Other' },
  ]);


  // const [sessionDelete, setSessionDelete] = useState(false);
  let sessionDelete = false;
  //   const []
  const detailObject = {
    session_completed: null,
    date_invited: null,
    session_date: null,
    date_confirmed: null,
    coach_confirmed: null,
    themes: '',
    notes: '',
  };

  const inputElement = useRef(null)
  const token = sessionStorage.getItem('libra_admin_token');
  const data = JSON.parse(sessionStorage.getItem('mentoring_user'));
  // console.log("data======>>>>>",data)

  const focusInput = () => {
    inputElement.current.input.focus();
  };
  const getClients = async (id) => {
    let temp
    if (data.role === "client") {
      temp = `api/clients/${id}`
    } else {
      temp = `api/clients`
    }
    await axios
      .get(`${base_url}/${temp}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallClients(res?.data?.data?.clients);
        if (data.role === "client") {
          setFieldValue('client_id', res?.data?.data?.client?.id);
          setallClients(res?.data?.data?.client?.name)
        } else {
          setClientId(res?.data?.data?.client?.id)
        }
        // setallClients(res?.data?.data?.client?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getParticipant = async () => {
    await axios
      .get(`${base_url}/api/participants`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallParticipants(res?.data?.data?.participants);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCoaches = async () => {
    await axios
      .get(`${base_url}/api/coaches`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCoaches(res?.data?.data?.coaches);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProgrammes = async () => {
    await axios
      .get(`${base_url}/api/programmes`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallProgrammes(res?.data?.data?.programmes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getClients(data.id);
    getParticipant();
    getCoaches();
    getProgrammes();
  }, []);

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required('Session group name is required'),
    client_id: Yup.number().required('Client name is required'),
    participant_id: Yup.string().required('Participant name is required'),
    coach_id: Yup.string().required('Coach name is required'),
    programme_id: Yup.string().required('Programme name is required'),
    detail: Yup.array()
      .of(
        Yup.object().shape({
          session_date: Yup.string('Session date is required').required('Session date is required').nullable(),
          // session_completed: Yup.string('Session complete date is required').required('Session complete date is required').nullable(),
          date_invited: Yup.string('Date invited is required').required('Date invited is required').nullable(),
          date_confirmed: Yup.string('Date confirmed is required').required('Date confirmed is required').nullable(),
          coach_confirmed: Yup.string('Coach confirmed is required').required('Coach confirmed is required').nullable(),
          // themes: Yup.string().required('Theme is required'),
          // themes: Yup.array().required('Programme name is required').min(1, 'Programme field must have atleast 1 item'),
          // notes: Yup.string().required('Notes is required'),
          session_duration: Yup.string().required('Session Duration name is required'),
        })
      )
      .required('Session date is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      client_id: '',
      participant_id: '',
      coach_id: '',
      programme_id: '',
      detail: [
        {
          session_completed: null,
          date_invited: null,
          session_date: null,
          date_confirmed: null,
          coach_confirmed: null,
          session_duration: '',
          session_status: '',
          session_location: '',
          themes: [],

          notes: '',
        },
      ],
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      console.log("testing")
      setloadingState(true);
      console.log('====================================');
      console.log("values", values);
      console.log('====================================');
      // console.log(values);
      // formData.append('name', values?.name);
      formData.append('client_id', values?.client_id);
      formData.append('participant_id', values?.participant_id);
      formData.append('programme_id', values?.programme_id);
      formData.append('coach_id', values?.coach_id);
      for (let i = 0; i < values?.detail?.length; i++) {
        formData.append(`session_number[${i}]`, i + 1);
        formData.append(`session_date[${i}]`, moment(values?.detail[i]?.session_date).format('YYYY-MM-DD HH:mm:ss'));
        formData.append(`date_invited[${i}]`, values?.detail[i]?.date_invited);
        formData.append(`date_confirmed[${i}]`, values?.detail[i]?.date_confirmed);
        formData.append(`coach_confirmed[${i}]`, values?.detail[i]?.coach_confirmed);
        formData.append(`session_completed[${i}]`,values?.detail[i]?.session_completed ? moment(values?.detail[i]?.session_completed).format('YYYY-MM-DD HH:mm:ss') : null);
        formData.append(`session_duration[${i}]`, values?.detail[i]?.session_duration);
        formData.append(`session_status[${i}]`, values?.detail[i]?.session_status);
        formData.append(`session_location[${i}]`, values?.detail[i]?.session_location);
        formData.append(`themes[${i}]`, values?.detail[i]?.themes);
        formData.append(`notes[${i}]`, values?.detail[i]?.notes);
      }

      axios
        .post(`${base_url}/api/session-groups`, formData, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setloadingState(false);
          console.log("response", res);
          if (res?.data?.status === true) {
            toast.success('Session group added successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/sessions', { replace: true });
            }, 2000);
            // getList();
          }
        })
        .catch((err) => {
          setloadingState(false);
          console.log("error", err)
          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            console.log("error", err)
            toast.error('Server Error');
          }
        });
    },
  });

  const { errors, values, touched, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;
  console.log("errors?.detail?.[0]?.session_date", errors)
  // setSessionDateError(errors?.detail?.[0]?.session_date);
  // setSessionCompleteDateError(errors?.detail?.[0]?.session_completed);
  const clearData = () => {
    resetForm();
  };
  const handleNewToggleButton = () => {
    setSessionLoading(true);
    detailsArray.length <= 9 && setDetailsArray([...detailsArray, detailsArray.length]);
    console.log("detailsArray", detailsArray);
    const detailarray = [...formik.values.detail];
    console.log("detailarray", detailarray)
    detailarray.push(detailObject);
    console.log("detailObject", detailObject)
    formik.values.detail.length <= 9 && formik.setFieldValue('detail', detailarray);
    setSessionLoading(false);
  };

  const handleSessionDelete = (id) => {
    var newArray = [...detailsArray];
    newArray.splice(id, 1);
    detailsArray.length > 1 && setDetailsArray(newArray);
    var newDetailsArray = [...formik.values.detail];
    newDetailsArray.length > 1 && newDetailsArray.splice(id, 1);

    if (formik.values.detail.length > 1) {
      formik.setFieldValue('detail', newDetailsArray);
      setAlignment(0);
    }
  };
  const handleAlignment = (e, newAlignment) => {
    if (sessionDelete) {
      sessionDelete = false;
    } else {
      setAlignment(newAlignment);
    }
  };
  const openOther = (item) => {
    console.log('item', item.label);
    if (item?.label == 'Other') {
      console.log('inother', item.label);

      setModal(true);
    } else {
      setModal(false);
    }
  };
  const settingOther = (e) => {
    // setOther(e.target.value);
    // console.log('e.target.value', e.target.value);
    // setFieldValue(e.target.value);
    // console.log('alignment', alignment);
    // setFieldValue(e.target.value);
    const dataTarget = [];
    dataTarget.push(e.target.value);
    // setFieldValue(e.target.value);

    setFieldValue(`detail[${alignment}].themes`, dataTarget);
  };
  const handleClose = () => {
    setModal(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  let themeValue = formik?.values?.detail?.[alignment]?.themes;
  themeValue =
    themeValue && typeof themeValue === 'string' ? themeValue?.split(',') : themeValue?.length ? themeValue : []

  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        Sessions
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Overview
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        This is where you can manage the connected participant, programme, coach and sponsor.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Modal
            open={modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography variant="h1" className={classes.heading}>
                Others
              </Typography>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  // autoComplete="username"
                  type="text"
                  label="Other"
                  onChange={(e) => settingOther(e)}
                // error={Boolean(touched.name && errors.name)}
                // helperText={touched.name && errors.name}
                />
              </Stack>
              <Button
                sx={{ mt: '15px', float: 'right' }}
                size="large"
                type="submit"
                variant="contained"
                // loading={loadingState}
                onClick={handleClose}
              >
                Save
              </Button>
            </Box>
          </Modal>
          <Stack spacing={3}>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              <Stack direction="row" spacing={5}>
                {data.role === "client" ?
                  <input type="text" disabled={true}
                    className={classes.clientField} value={allClients} /> :
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allClients}
                    onChange={(e, value) => {
                      setFieldValue('client_id', value?.id);
                    }}
                    onOpen={handleBlur}
                    // renderOption={(option) => <>{option.name}</>}
                    getOptionLabel={(allClients) => allClients?.name}
                    // includeInputInList
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.client_id && errors.client_id)}
                        fullWidth
                        helperText={touched.client_id && errors.client_id}
                        name="client_id"
                        variant="outlined"
                        label="Client"
                      />
                    )}
                    fullWidth={true}
                  />}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_id && errors.coach_id)}
                      fullWidth
                      helperText={touched.coach_id && errors.coach_id}
                      name="coach_id"
                      variant="outlined"
                      label="Coach"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allParticipants}
                  onChange={(e, value) => {
                    setFieldValue('participant_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.participant_id && errors.participant_id)}
                      fullWidth
                      helperText={touched.participant_id && errors.participant_id}
                      name="participant_id"
                      variant="outlined"
                      label="Participant Name"
                    />
                  )}
                  fullWidth={true}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allProgrammes}
                  onChange={(e, value) => {
                    setFieldValue('programme_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.programme_id && errors.programme_id)}
                      fullWidth
                      helperText={touched.programme_id && errors.programme_id}
                      name="programme_id"
                      variant="outlined"
                      label="Programme Name"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
              {/* <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Session Group Name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Box style={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="text"
                    label="Session Group Name*"
                    style={{ display: 'none' }}
                  />
                </Box>
              </Stack> */}
            </Stack>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '50px',
            }}
          >
            <Box>
              <ToggleButtonGroup
                variant="text"
                value={alignment}
                exclusive
                onChange={handleAlignment}
                color="primary"
                className={classes.btncontainer}
                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '1000px' }}
              >
                {detailsArray.map((btn, index) => (
                  <ToggleButton
                    key={index}
                    value={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      wordBreak: 'keep-all',
                    }}
                  >
                    Session {index + 1}{' '}
                    <CloseIcon
                      onClick={() => {
                        sessionDelete = true;
                        handleSessionDelete(index);
                      }}
                    />
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <Button
              onClick={handleNewToggleButton}
              // disable={sessionLoading}
              startIcon={<AddIcon />}
              className={classes.addButton}
            >
              Add another
            </Button>
          </Box>
          <Box>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              {/* <Typography variant="body1" style={{ color: 'red' }} className={classes.body1}>
                Make sure all session fields are filled.
              </Typography> */}

              <Grid container style={{ marginBottom: '40px', marginTop: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Date invited
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '320px' }}>
                    <Stack spacing={3} style={{ width: '320px' }}>
                      <DatePicker
                        // label="Cohort year"
                        // disablePast
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____"
                        ampm={false}
                        id={`detail[${alignment}].date_invited`}
                        name={`detail[${alignment}].date_invited`}
                        value={formik.values?.detail?.[alignment]?.date_invited}
                        // disablePast
                        onChange={(value) =>
                          formik.setFieldValue(`detail[${alignment}].date_invited`, moment(value).format('YYYY-MM-DD'))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.detail?.[alignment]?.date_invited &&
                              Boolean(formik.errors.detail?.[alignment]?.date_invited)
                            }
                            helperText={
                              formik.touched.detail?.[alignment]?.date_invited &&
                              formik.errors.detail?.[alignment]?.date_invited
                            }
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Date confirmed
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '320px' }}>
                    <Stack spacing={3} style={{ width: '320px' }}>
                      <DatePicker
                        // label="Cohort year"
                        // disablePast
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____"
                        ampm={false}
                        id={`detail[${alignment}].date_confirmed`}
                        name={`detail[${alignment}].date_confirmed`}
                        value={formik.values?.detail?.[alignment]?.date_confirmed}
                        // disablePast
                        onChange={(value) =>
                          formik.setFieldValue(
                            `detail[${alignment}].date_confirmed`,
                            moment(value).format('YYYY-MM-DD')
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.detail?.[alignment]?.date_confirmed &&
                              Boolean(formik.errors.detail?.[alignment]?.date_confirmed)
                            }
                            helperText={
                              formik.touched.detail?.[alignment]?.date_confirmed &&
                              formik.errors.detail?.[alignment]?.date_confirmed
                            }
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Coach confirmed
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '320px' }}>
                    <Stack spacing={3} style={{ width: '320px' }}>
                      <DatePicker
                        // label="Cohort year"
                        // disablePast
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____ __:__"
                        ampm={false}
                        id={`detail[${alignment}].coach_confirmed`}
                        name={`detail[${alignment}].coach_confirmed`}
                        value={formik.values?.detail?.[alignment]?.coach_confirmed}
                        // disablePast
                        onChange={(value) =>
                          formik.setFieldValue(
                            `detail[${alignment}].coach_confirmed`,
                            moment(value).format('YYYY-MM-DD h:mm:ss')
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.detail?.[alignment]?.coach_confirmed &&
                              Boolean(formik.errors.detail?.[alignment]?.coach_confirmed)
                            }
                            helperText={
                              formik.touched.detail?.[alignment]?.coach_confirmed &&
                              formik.errors.detail?.[alignment]?.coach_confirmed
                            }
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Session date
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {console.log("touch", formik.touched.detail?.[alignment]?.session_date)}
                  <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '320px' }}>
                    <Stack spacing={3} style={{ width: '320px', position: "relative" }}>
                      <div className={formik.touched.detail?.[alignment]?.session_date && errors?.detail?.[0]?.session_date ? "session-error-msg" : "session-input-box"}>
                        <Stack spacing={3} style={{ width: '318px' }}>
                          <RSDatePicker
                            className="picker-date date-placeholder"
                            // shouldDisableDate
                            hideSeconds
                            hideMinutes={minute => minute % 15 !== 0}
                            // disabledDate={true}
                            placeholder="dd/mm/yyyy hh:mm"
                            format="dd/MM/yyyy HH:mm"
                            open={sessionDate}
                            value={
                              formik.values?.detail?.[alignment]?.session_date
                                ? new Date(formik.values?.detail?.[alignment]?.session_date)
                                : null
                            }
                            onChange={(value) => {
                              formik.setFieldValue(
                                `detail[${alignment}].session_date`,
                                value
                              );
                              // moment(value).format('YYYY-MM-DD h:mm:ss')
                            }}
                            onOk={(value) => {
                              formik.setFieldValue(
                                `detail[${alignment}].session_date`,
                                value
                              ); setSessionDate(false)
                            }}

                          />
                        </Stack>
                        <img src={CalenderIcon} onClick={() => setSessionDate(true)} className="calender-icon" />

                      </div>
                      <p style={{ color: "red", fontSize: "12px", margin: "3px 14px 0px" }}>{formik.touched.detail?.[alignment]?.session_date && errors?.detail?.[0]?.session_date}</p>



                      {/* <DateTimePicker
                        // label="Cohort year"
                        inputFormat="dd/MM/yyyy h:mm:ss"
                        mask="__/__/____ __:__"
                        ampm={false}
                        id={`detail[${alignment}].session_date`}
                        name={`detail[${alignment}].session_date`}
                        value={formik.values?.detail?.[alignment]?.session_date}
                        // disablePast
                        onChange={(value) => {
                          setDateMatch(moment(value).format('YYYY-MM-DD'));
                          formik.setFieldValue(
                            `detail[${alignment}].session_date`,
                            moment(value).format('YYYY-MM-DD h:mm:ss')
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.detail?.[alignment]?.session_date &&
                              Boolean(formik.errors.detail?.[alignment]?.session_date)
                            }
                            helperText={
                              formik.touched.detail?.[alignment]?.session_date &&
                              formik.errors.detail?.[alignment]?.session_date
                            }
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      /> */}

                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Session completed
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {console.log("errors?.detail?.[0]?.session_completed", errors?.detail?.[0]?.session_completed)}
                    <Stack spacing={3} style={{ width: '320px', position: "relative" }}>
                      <div className={formik.touched.detail?.[alignment]?.session_completed && errors?.detail?.[0]?.session_completed ? "session-error-msg" : "session-input-box"}>
                        <Stack spacing={3} style={{ width: '318px' }}>
                          <RSDatePicker
                            hideSeconds
                            placeholder="dd/mm/yyyy hh:mm"
                            format="dd/MM/yyyy HH:mm"
                            hideMinutes={minute => minute % 15 !== 0}
                            value={formik.values?.detail?.[alignment]?.session_completed
                              ? new Date(formik.values?.detail?.[alignment]?.session_completed)
                              : null
                            }
                            disablePast
                            open={sessionCompleteDate}
                            onChange={(value) => {
                              formik.setFieldValue(
                                `detail[${alignment}].session_completed`,
                                value
                              ); setSessionCompleteDate(false)
                              // moment(value).format('YYYY-MM-DD h:mm:ss')
                            }}
                            onOk={(value) => {
                              formik.setFieldValue(
                                `detail[${alignment}].session_completed`,
                                value
                              );
                            }}
                          // calendarDefaultDate={new Date('2022-02-02 00:00:00')}
                          />
                        </Stack>
                        <img src={CalenderIcon} onClick={() => setSessionCompleteDate(true)} className="calender-icon" />
                      </div>
                      <p style={{ color: "red", fontSize: "12px", margin: "3px 14px 0px" }}>{formik.touched.detail?.[alignment]?.session_completed && errors?.detail?.[0]?.session_completed}</p>
                      {/* <DateTimePicker
                        // label="Cohort year"
                        // disablePast
                        inputFormat="dd/MM/yyyy h:mm:ss"  
                        mask="__/__/____ __:__"
                        ampm={false}
                        // label="Cohort year"
                        minDate={dateMatch}
                        // shouldDisableDate={(date) => {
                        //   const fromFormatted = moment(date).format('YYYY-MM-DD');
                        //   return dateMatch === fromFormatted ? true : false;
                        // }}
                        id={`detail[${alignment}].session_completed`}
                        name={`detail[${alignment}].session_completed`}
                        value={formik.values?.detail?.[alignment]?.session_completed}
                        // disablePast
                        onChange={
                          (value) =>
                            formik.setFieldValue(
                              `detail[${alignment}].session_completed`,
                              moment(value).format('YYYY-MM-DD h:mm:ss')
                            )
                          //   {
                          //   const dataDell = moment(value).format('YYYY-MM-DD');
                          //   console.log('value', dataDell);
                          //   if (dateMatch === dataDell) {
                          //     console.log('first');
                          //     toast.error('kuch b');
                          //   } else {
                          //     formik.setFieldValue(
                          //       `detail[${alignment}].session_completed`,
                          //       moment(value).format('YYYY-MM-DD')
                          //     );
                          //   }
                          // }
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.detail?.[alignment]?.session_completed &&
                              Boolean(formik.errors.detail?.[alignment]?.session_completed)
                            }
                            helperText={
                              formik.touched.detail?.[alignment]?.session_completed &&
                              formik.errors.detail?.[alignment]?.session_completed
                            }
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      /> */}
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Session Duration
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack spacing={3} style={{ width: '320px' }}>
                    <Autocomplete
                      disablePortal
                      // id="combo-box-demo"
                      id={`detail[${alignment}].session_duration`}
                      name={`detail[${alignment}].session_duration`}
                      options={time}
                      value={formik.values?.detail?.[alignment]?.session_duration || ""}
                      // onChange={formik.handleChange}
                      onChange={(e, value) => {
                        console.log('value', value.label);
                        // formik.resetForm();
                        formik.setFieldValue(`detail[${alignment}].session_duration`, value?.label);
                        // setcompanyID(value?.id);
                      }}
                      style={{ marginBottom: '25px' }}
                      onOpen={handleBlur}
                      // getOptionLabel={(options) => options.label}
                      renderInput={(params) => {
                        console.log(params, "parmas ");
                        return <TextField
                          {...params}
                          error={
                            formik.touched.detail?.[alignment]?.session_duration &&
                            Boolean(formik.errors.detail?.[alignment]?.session_duration)
                          }
                          helperText={
                            formik.touched.detail?.[alignment]?.session_duration &&
                            formik.errors.detail?.[alignment]?.session_duration
                          }
                          // error={Boolean(touched.session_duration && errors.session_duration)}
                          fullWidth
                          // helperText={touched.session_duration && errors.session_duration}
                          // name="session_duration"
                          variant="outlined"
                          label="Session Duration"
                        />
                      }}
                      fullWidth={true}
                    />
                  </Stack>
                </Grid>
              </Grid>
              
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Session Status
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack spacing={3} style={{ width: '320px' }}>
                    <Autocomplete
                      disablePortal
                      // id="combo-box-demo"
                      id={`detail[${alignment}].session_status`}
                      name={`detail[${alignment}].session_status`}
                      options={status}
                      value={formik.values?.detail?.[alignment]?.session_status || ""}
                      // onChange={formik.handleChange}
                      onChange={(e, value) => {
                        console.log('value', value.label);
                        // formik.resetForm();
                        formik.setFieldValue(`detail[${alignment}].session_status`, value?.label);
                        // setcompanyID(value?.id);
                      }}
                      style={{ marginBottom: '25px' }}
                      onOpen={handleBlur}
                      // getOptionLabel={(options) => options.label}
                      renderInput={(params) => {
                        console.log(params, "parmas ");
                        return <TextField
                          {...params}
                          error={
                            formik.touched.detail?.[alignment]?.session_status &&
                            Boolean(formik.errors.detail?.[alignment]?.session_status)
                          }
                          helperText={
                            formik.touched.detail?.[alignment]?.session_status &&
                            formik.errors.detail?.[alignment]?.session_status
                          }
                          // error={Boolean(touched.session_duration && errors.session_duration)}
                          fullWidth
                          // helperText={touched.session_duration && errors.session_duration}
                          // name="session_duration"
                          variant="outlined"
                          label="Session Status"
                        />
                      }}
                      fullWidth={true}
                    />
                  </Stack>
                </Grid>
              </Grid>
              
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Session Location
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    multiline
                    rows={1}
                    id={`detail[${alignment}].session_location`}
                    name={`detail[${alignment}].session_location`}
                    type="text"
                    value={formik.values?.detail?.[alignment]?.session_location}
                    placeholder="Location 1"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.detail?.[alignment]?.session_location && Boolean(formik.errors.detail?.[alignment]?.session_location)
                    }
                    helperText={formik.touched.detail?.[alignment]?.session_location && formik.errors.detail?.[alignment]?.session_location}
                  />
                </Grid>
              </Grid>
              
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Themes
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Autocomplete
                    multiple
                    disablePortal
                    options={companyID}
                    value={themeValue}
                    onChange={(e, values) => {
                      const data = [];
                      values.map((item) => {
                        console.log('values12', item);
                        data.push(item?.label);

                        openOther(item);
                      });
                      // console.log('values1', data);
                      setFieldValue(`detail[${alignment}].themes`, data);

                      // setAutoValue(values.label != 'Other' ? values.label : other);

                      // setModal(false);

                      // setallCompanies(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={`detail[${alignment}].themes`}
                        name={`detail[${alignment}].themes`}
                        type="text"
                        value={formik.values?.detail?.[alignment]?.themes}
                        // value={other === '' ? formik.values?.detail?.[alignment]?.themes : other}
                        placeholder="Theme A, Theme B, Theme C..."
                        error={
                          formik.touched.detail?.[alignment]?.themes &&
                          Boolean(formik.errors.detail?.[alignment]?.themes)
                        }
                        helperText={
                          formik.touched.detail?.[alignment]?.themes && formik.errors.detail?.[alignment]?.themes
                        }
                      />
                    )}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              
              <Grid container style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Notes
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add comments to the session.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    id={`detail[${alignment}].notes`}
                    name={`detail[${alignment}].notes`}
                    type="text"
                    value={formik.values?.detail?.[alignment]?.notes}
                    placeholder="I found the session great with the participant, we would like to work more on..."
                    onChange={formik.handleChange}
                    error={
                      formik.touched.detail?.[alignment]?.notes && Boolean(formik.errors.detail?.[alignment]?.notes)
                    }
                    helperText={formik.touched.detail?.[alignment]?.notes && formik.errors.detail?.[alignment]?.notes}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/sessions', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      {/* <SessionTab /> */}

      <Toaster />
    </div>
  );
}







// "SQLSTATE[22007]: Invalid datetime format: 1292 Incorrect datetime value: 'Invalid date' 
// for column `libra_backend`.`sessions`.`session_date` at row 1 
// (SQL: insert into `sessions` (`session_group_id`, `session_number`, `session_date`, 
// `session_duration`, `date_invited`, `date_confirmed`, `coach_confirmed`, 
// `session_completed`, `themes`, `notes`, `updated_at`, `created_at`) 
// values (122, 1, Invalid date, ?, ?, ?, ?, Invalid date, ?, ?, 2023-09-26 07:34:36, 
//   2023-09-26 07:34:36))"