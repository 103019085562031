// export const base_url = 'https://api.appliedcoaching.co/public';
// export const base_url = 'https://mentoring-libra.herokuapp.com';

// export const base_url = 'http://172.93.52.157:8051/public';

export const base_url = 'https://acebackend.developmentsites.xyz';
// export const base_url = 'https://api.ace-coaching.com';
// export const base_url = 'https://api.ace-coaching.com';
// export const base_url = 'http://54.164.13.248';
// export const img_base_url = 'https://libra-mentorings.s3.eu-west-2.amazonaws.com';
export const img_base_url = 'https://acebackend.developmentsites.xyz/storage/';
// export const base_url = 'http://44.207.35.56/libra-backend/public';
