import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dropzone, FileItem, FullScreenPreview } from '@dropzone-ui/react';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import { base_url } from 'src/common';
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@material-ui/pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { useDropzone } from 'react-dropzone';
import MaterialDropZone from './materialDropZone';
import { useTheme } from '@mui/material/styles';
const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  wrapper_class: {
    padding: '1rem',
    border: '1px solid #ccc',
    marginBottom: '1.5rem',
  },
  editor_class: {
    backgroundColor: 'lightgray',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  toolbar_class: {
    border: '1px solid #ccc',
  },
  dropzone: {
    textSlign: 'center',
    padding: '20px',
    width: '512px',
    height: '126',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed #EAECF0',
    borderRadius: '8px',
    '& img': {
      width: '50px',
    },
  },
}));

export default function AdminAddProgramme(props) {
  const navigate = useNavigate();

  const classes = useStyle();
  const formData = new FormData();
  const [loadingState, setloadingState] = useState(false);
  const [dateValue, setDateValue] = React.useState(null);
  const [allClients, setallClients] = useState([]);
  const [allGroups, setallGroups] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const [allCompanies, setallCompanies] = useState([]);
  const [companyID, setcompanyID] = useState(null);
  // Dropzone/ui
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);

  //
   const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ItineraryeditorState, setItineraryEditorState] = useState(() => EditorState.createEmpty());
  const [materialeditorState, setmaterialEditorState] = useState(() => EditorState.createEmpty());

  const token = sessionStorage.getItem('libra_admin_token');
  const getAllCompanies = async () => {
    await axios
      .get(`${base_url}/api/companies`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCompanies(res?.data?.data?.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getClients = async () => {
  //   await axios
  //     .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((res) => {
  //       setallClients(res?.data?.data?.clients);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const getGroups = async () => {
    await axios
      .get(`${base_url}/api/groups`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallGroups(res?.data?.data?.groups);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getCoaches = async () => {
  //   await axios
  //     .get(`${base_url}/api/coaches`, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((res) => {
  //       setallCoaches(res?.data?.data?.coaches);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    getAllCompanies();
    // getClients();
    getGroups();
    // getCoaches();
  }, []);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    client_ids: Yup.array().required('Client name is required').min(1, 'Client field must have atleast 1 item'),
    company_id: Yup.string().required('Coaching Provider name is required'),
    group_id: Yup.string().required('Group name is required'),
    coach_ids: Yup.array().required('Coach name is required').min(1, 'Coach field must have atleast 1 item'),
    cohort_year: Yup.string().required('Cohort year is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      client_ids: [],
      group_id: '',
      company_id: '',
      coach_ids: [],
      cohort_year: '',
      itinerary: '',
      program_material: '',
      material_documents: [],
      itinerary_documents: [],
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      console.log('valuesdd', values);

      const dupObj = { ...values };
      const ids = dupObj.coach_ids.map((item) => item.id);
      dupObj.coach_ids = ids.toString();
      const clientids = dupObj.client_ids.map((item) => item.id);
      dupObj.client_ids = clientids.toString();
      formData.append('company_id', dupObj?.company_id);
      formData.append('name', dupObj?.name);
      formData.append('client_ids', dupObj?.client_ids);
      formData.append('coach_ids', dupObj?.coach_ids);
      formData.append('cohort_year', dupObj?.cohort_year);
      formData.append('group_id', dupObj?.group_id);
      formData.append('itinerary', dupObj?.itinerary);
      formData.append('program_material', dupObj?.program_material);
      if (dupObj.itinerary_documents.length > 0) {
        for (let i = 0; i < dupObj.itinerary_documents.length; i++) {
          formData.append('itinerary_documents[]', dupObj?.itinerary_documents[i] && dupObj?.itinerary_documents[i]);
        }
      }
      if (dupObj.material_documents.length > 0) {
        for (let i = 0; i < dupObj.material_documents.length; i++) {
          formData.append('material_documents[]', dupObj?.material_documents[i] && dupObj?.material_documents[i]);
        }
      }

      axios
        .post(`${base_url}/api/programmes`, formData, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setloadingState(false);

          if (res?.data?.status === true) {
            toast.success('Programme added successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/programmes', { replace: true });
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const { errors, touched, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;

  const clearData = () => {
    resetForm();
  };
  function onEditorStateChange(state) {
    setItineraryEditorState(state);
    ItineraryconvertContentToHTML();
  }
  const ItineraryconvertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(ItineraryeditorState.getCurrentContent());
    setFieldValue('itinerary', currentContentAsHTML);
  };
  function onmaterialEditorStateChange(state) {
    setmaterialEditorState(state);
    materialconvertContentToHTML();
  }
  const materialconvertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(materialeditorState.getCurrentContent());
    setFieldValue('program_material', currentContentAsHTML);
  };
  // const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
  //   accept: {
  //     'image/png': ['.Custom', '.jpeg', '.png', '.jpg', '.docx', '.pdf'],
  //     //   'application/pdf': ['.pdf'],
  //     //   'application/docs': ['.docx'],
  //   },
  //   // accept: 'image/png, image/jpeg,image/jpg,application/pdf,application/docs',
  // });
  // const acceptedFileItems = acceptedFiles.map((file) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  // useEffect(() => {
  //   setFieldValue('itinerary_documents', acceptedFiles);
  // }, [acceptedFiles]);

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //     <ul>
  //       {errors.map((e) => (
  //         <li key={e.code}>{e.message}</li>
  //       ))}
  //     </ul>
  //   </li>
  // ));
  // React dropzone/ui
  const updateFiles = (incommingFiles) => {
    console.log('incomming files', incommingFiles);
    const update = [];
    incommingFiles?.map((item) => update.push(item?.file));
    setFieldValue('itinerary_documents', update);

    setFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    console.log('imageSource', imageSource);
    setFieldValue(imageSource);
  };
  const handleClean = (files) => {
    console.log('list cleaned', files);
  };
  //
  useEffect(() => {
    const selectedCompanyData = async () => {
      await axios
        .get(`${base_url}/api/companies/${companyID}/users`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setallClients(res?.data?.data?.clients);
          setallCoaches(res?.data?.data?.coaches);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    selectedCompanyData();
  }, [companyID]);
  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        Programme
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Management
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        Manage which client, coach and participant can view this programme.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allCompanies}
                onChange={(e, value) => {
                  formik.resetForm();

                  setFieldValue('company_id', value?.id);
                  setcompanyID(value?.id);
                }}
                style={{ marginBottom: '25px' }}
                onOpen={handleBlur}
                getOptionLabel={(options) => options.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.company_id && errors.company_id)}
                    fullWidth
                    helperText={touched.company_id && errors.company_id}
                    name="company_id"
                    variant="outlined"
                    label="Coaching Provider"
                  />
                )}
                fullWidth={true}
              />
              <Stack direction={isMobile ? "column" : "row"} spacing={5}>
                <Autocomplete
                  disablePortal
                  multiple
                  id="tags-standard"
                  options={allClients}
                  onChange={(e, value) => {
                    console.log('company_id', value);
                    setFieldValue('client_ids', value);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients.name}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_ids && errors.client_ids)}
                      fullWidth
                      helperText={touched.client_ids && errors.client_ids}
                      name="client_ids"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                />
                <Autocomplete
                  multiple
                  disablePortal
                  id="tags-standard"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_ids', value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_ids && errors.coach_ids)}
                      fullWidth
                      helperText={touched.coach_ids && errors.coach_ids}
                      name="coach_ids"
                      variant="outlined"
                      label="Coaches"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} sx={{ marginTop: '25px' }} spacing={5}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allGroups}
                  onChange={(e, value) => {
                    setFieldValue('group_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.group_id && errors.group_id)}
                      fullWidth
                      helperText={touched.group_id && errors.group_id}
                      name="group_id"
                      variant="outlined"
                      label="Participant group"
                    />
                  )}
                  fullWidth={true}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3} style={{ width: '100%' }}>
                    <DatePicker
                      views={['year']}
                      label="Cohort year"
                      disablePast
                      value={dateValue}
                      onChange={(value) => {
                        setDateValue(value);
                        setFieldValue('cohort_year', moment(value).format('YYYY'));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.cohort_year && errors.cohort_year)}
                          fullWidth
                          helperText={touched.cohort_year && errors.cohort_year}
                          name="cohort_year"
                          variant="outlined"
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>
            </Stack>
            <Typography variant="body1" className={classes.ParticipantDetails}>
              Programme details
            </Typography> 
            <Typography variant="subtitle1" className={classes.body2text}>
              Add details for the programme.
            </Typography>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Programme name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
              <Grid container style={{ marginBottom: '50px', marginTop: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Itinerary
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add the programme Itinerary.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    defaultEditorState={ItineraryeditorState}
                    editorState={ItineraryeditorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  <section className="container" >
                    {/* <Box>
                      <div {...getRootProps({ className: `${classes.dropzone}` })}>
                        <input {...getInputProps()} />
                        <img src="/static/icons/Featuredicon.png" />
                        <p
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#667085',
                          }}
                        >
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              fontSize: '14px',
                              lineHeight: '20px',
                              color: '#6941C6',
                            }}
                          >
                            Click to upload
                          </span>
                          or drag and drop
                        </p>
                        <p
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            textAlign: 'center',
                            color: '#667085',
                          }}
                        >
                          Only PDF, .docx or JPG,JPEG and PNG(max. 5mb) can be uploaded
                        </p>
                      </div>
                    </Box> */}
                    <Dropzone
                      // style={{ padding: '5px' }}
                      view={'grid'}
                      onChange={updateFiles}
                      minHeight="195px"
                      onClean={handleClean}
                      value={files}
                      // maxFiles={10}
                      //header={false}
                      // footer={false}
                      maxFileSize={20971520}
                      label="(Max. 20mb) can be uploaded"
                      //label="Suleta tus archivos aquí"
                      // accept="image/*, application/pdf"
                      // uploadingMessage={"Uploading..."}
                      // url="https://my-awsome-server/upload-my-file"
                      //of course this url doens´t work, is only to make upload button visible
                      //uploadOnDrop
                      //clickable={false}
                      fakeUploading
                      //localization={"FR-fr"}
                      disableScroll
                    >
                      {files?.length > 0 &&
                        files?.map((file) => (
                          <FileItem
                            {...file}
                            key={file.id}
                            onDelete={onDelete}
                            onSee={handleSee}
                            //localization={"ES-es"}
                            resultOnTooltip
                            preview
                            info
                            hd
                          />
                        ))}
                    </Dropzone>
                    <FullScreenPreview
                      imgSource={imageSrc}
                      openImage={imageSrc}
                      onClose={(e) => handleSee(undefined)}
                    />
                    {/* <aside>
                      <h4>Accepted files:</h4>
                      <ul>{acceptedFileItems}</ul>
                      <h4>Rejected files:</h4>
                      <ul>{fileRejectionItems}</ul>
                    </aside> */}
                  </section>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Programme materials
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add the programme materials.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    defaultEditorState={materialeditorState}
                    editorState={materialeditorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onmaterialEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  <MaterialDropZone
                    classes={classes}
                    itinerary_documents={formik?.initialValues?.itinerary_documents}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent={isMobile ? "center" : "end"} sx={{ mt: 3, mb: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/programmes', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{
                marginRight: '1rem',
                fontSize: isMobile ? '1.25rem' : '0.875rem', // Adjust font size for mobile
                padding: isMobile ? '16px 32px' : '8px 16px', // Adjust padding for mobile
                minWidth: isMobile ? '150px' : 'auto', // Ensure button has a minimum width on mobile
              }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}
            sx={{
                marginRight: '1rem',
                fontSize: isMobile ? '1.25rem' : '0.875rem', // Adjust font size for mobile
                padding: isMobile ? '16px 32px' : '8px 16px', // Adjust padding for mobile
                minWidth: isMobile ? '150px' : 'auto', // Ensure button has a minimum width on mobile
              }}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Toaster />
    </div>
  );
}
